import { isUndefined } from 'lodash-es';
import type { TrackBy } from '../shared/types';
import { getValueByKey } from './get-value-by-key';

export function areValuesEqual<V = unknown>(value1: V, value2: V, trackBy?: TrackBy<V>) {
  const trackedValue1 = trackBy ? getValueByKey(value1, trackBy) : value1;
  const trackedValue2 = trackBy ? getValueByKey(value2, trackBy) : value2;

  if (isUndefined(trackedValue1) || isUndefined(trackedValue2)) {
    return false;
  }

  return trackedValue1 === trackedValue2;
}
