<script lang="ts" setup generic="V = any">
import { computed, shallowRef } from 'vue';
import { useElementHover, useFocus } from '@vueuse/core';
import { areValuesEqual, hasSlotContent } from '../../utils';
import type { TrackBy } from '../../shared/types';
import { ObPrimitiveCheckbox } from '../primitive-checkbox';

type Props<Multiselect = boolean, ModelValue = Multiselect extends true ? V[] : V> = {
  ariaLabel?: string;
  label?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  invalid?: boolean;
  readonly?: boolean;
  // It's needed because Vue convert it to string if boolean type is not defined explicitly
  // <ObCheckbox multiselect />
  multiselect?: boolean & Multiselect;
  value?: V;
  falseValue?: V;
  trackBy?: TrackBy<V>;
  modelValue?: ModelValue;
};

const {
  ariaLabel,
  label,
  disabled = false,
  indeterminate = false,
  invalid = false,
  readonly = false,
  multiselect = false,
  value = true as V,
  falseValue = false as V,
  trackBy,
} = defineProps<Props>();

defineEmits<{
  'update:modelValue': [value: Props['multiselect'] extends true ? V[] : V];
}>();

const modelValue = defineModel<V | V[]>(); // Have to leave it empty because cannot use [] or falseValue here

const rootRef = shallowRef<HTMLLabelElement | null>(null);
const inputRef = shallowRef<HTMLInputElement | null>(null);

const hovered = useElementHover(rootRef);
const { focused } = useFocus(inputRef);

const checked = computed<boolean>(() => {
  if (typeof modelValue.value === 'undefined') {
    return false;
  }

  if (multiselect) {
    return Array.isArray(modelValue.value)
      ? modelValue.value.some((item) => areValuesEqual(item, value, trackBy))
      : false;
  }

  return !Array.isArray(modelValue.value) && areValuesEqual(modelValue.value, value, trackBy);
});

function onChange(event: Event): void {
  if (disabled) {
    return;
  }

  const { checked } = event.target as HTMLInputElement;

  if (multiselect) {
    if (!Array.isArray(modelValue.value)) {
      modelValue.value = [];
    }

    if (checked) {
      modelValue.value.push(value);
    } else {
      modelValue.value = modelValue.value.filter((item) => !areValuesEqual(item, value, trackBy));
    }
  } else {
    modelValue.value = checked ? value : falseValue;
  }
}
</script>

<template>
  <label ref="rootRef" :class="[$style.root, { [$style.disabled]: disabled }]">
    <input
      ref="inputRef"
      :aria-checked="indeterminate ? 'mixed' : checked"
      :aria-invalid="invalid ? true : undefined"
      :aria-readonly="readonly ? true : undefined"
      :aria-label="ariaLabel"
      :checked="checked"
      :disabled="disabled"
      :readonly="readonly"
      type="checkbox"
      :class="$style.input"
      @change="onChange"
    />
    <ObPrimitiveCheckbox
      :checked="checked"
      :disabled="disabled"
      :focused="focused"
      :hovered="hovered && !disabled"
      :indeterminate="indeterminate"
      :invalid="invalid"
    />
    <span v-if="label || hasSlotContent($slots.default)" :class="$style.label">
      <slot>{{ label }}</slot>
    </span>
  </label>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.root {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  color: colors.$primary;
  font-family: typography.$font-family-primary;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
}

.input {
  @include shared.sr-only();
}

.label {
  margin-left: 8px;
  flex: 1 1 0%;
  min-width: 0;
}

.disabled {
  color: colors.$surface-40;
  cursor: not-allowed;
}
</style>
